<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Users</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>  
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <ion-grid class="ricerca">
          <ion-row>
            <ion-col id="rcconsulente">
              <ion-label id="lconsulente">
                Username:
              </ion-label>
              <ion-input id="ricconsulente"  v-model="search.name"></ion-input>  
            </ion-col>
            <ion-col id="rctipologia">
              <ion-label id="ltipologia">
                Email:
              </ion-label>
              <ion-input id="ricconsulente"  v-model="search.email"></ion-input>  
            </ion-col>
            <ion-col id="rctipologia">
              <ion-label id="ltipologia">
                Company:
              </ion-label>
              <ion-input id="ricconsulente"  v-model="search.company"></ion-input>  
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col id="rctipologia">
              <ion-label id="ltipologia">
                Livello:
              </ion-label>
              <ion-select id="ricconsulente"  v-model="search.role">
                <ion-select-option :value="r" v-for="r,i in ruoli" :key="'role-'+i">{{roles[i]}}</ion-select-option>
              </ion-select>  
            </ion-col>
            <ion-col>

            </ion-col>
            <ion-col>

            </ion-col>
          </ion-row>
          <ion-row>
            <ion-button class="btncerca" @click="getUsers()">
                RICERCA
            </ion-button>
            <ion-button class="btnfiltro" color='warning' @click="svuotaFiltro()">
              X
            </ion-button>
          </ion-row>
        </ion-grid>
        <ion-button id="nuovobtn" @click="$router.push('/folder/user')">NUOVO</ion-button>
        <ion-button id="nuovobtn" color="warning" @click="getDisattivi()" v-if="flagDisattivi==0">VEDI DISABILITATI</ion-button>
        <ion-button id="nuovobtn" color="success" @click="getUsers()" v-if="flagDisattivi==1">VEDI ABILITATI</ion-button>
        <dtable          
          color="#fff"
          bgcolor="rgb(56,128,255)"
          :heading="UserHeading"
          :data="users"
          :total="table.total"
          :perPage="table.perPage"
          :currentPage="table.currentPage"
          :orderBy="table.orderBy"
          :order="table.order"
          @changePage="getUsers"
        >
          <template v-slot:btns="slotProps">
            <ion-button color="danger" @click="alertDelete(slotProps.row.id)" v-if="slotProps.row.attivo==1">
              <ion-icon :icon="trashOutline">
            </ion-icon>
            </ion-button> 
            <ion-button color="success" @click="attivaUser(slotProps.row.id)" v-if="slotProps.row.attivo==0">
              ATTIVA
            </ion-button> 
            <ion-button @click="$router.push('/folder/user-modifica/'+slotProps.row.id);">
              <ion-icon :icon="pencilOutline"></ion-icon>
            </ion-button>
          </template>
          <template v-slot:role="slotProps">
            <div v-if="slotProps.row.role==0">Cliente</div>
            <div v-if="slotProps.row.role==1">Consulente</div>
            <div v-if="slotProps.row.role==2">Interinale</div>
            <div v-if="slotProps.row.role==3">Stagista</div>
            <div v-if="slotProps.row.role==4">Dipendente</div>
            <div v-if="slotProps.row.role==5">Admin</div>
            <div v-if="slotProps.row.role==6">Company admin</div>
            <div v-if="slotProps.row.role==7">Superadmin</div>
          </template>
         </dtable>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonLabel,
    IonMenuButton, 
    IonPage,
    IonRow, 
    IonSelect,
    IonSelectOption,
    IonTitle, 
    IonToolbar,
    alertController
  } from '@ionic/vue';
  import {pencilOutline, trashOutline} from 'ionicons/icons';
  import Dtable from './partials/Dtable';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons,
      IonCol,
      IonContent,
      IonGrid,
      IonHeader,
      IonIcon,
      IonInput,
      IonLabel,
      IonMenuButton, 
      IonPage,
      IonRow, 
      IonSelect,
      IonSelectOption,
      IonTitle, 
      IonToolbar,
      Dtable,
    },
    data: function(){
      return{
        users:[],
        pencilOutline:pencilOutline,
        trashOutline:trashOutline,
        UserHeading: [
          {
            text: 'Username',
            code: 'name'
          },
          {
            text: 'Email',
            code: 'email'
          },
          {
            text: 'Company',
            code: 'company'
          },
          {
            text: 'Livello',
            code: 'role'
          },
          {
            text: '',
            code: 'btns'
          }           
        ],
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'name',
          order: 'asc',
        },
        search:{
          name:'',
          email:'',
          company:'',
          role:'',
        },
        flagDisattivi:0,
        ruoli:[0,1,2,3,4,5,6,7],
        roles:['Cliente','Consulente','Interinale','Stagista','Dipendente','Admin','Company admin','Superadmin'],
      }
    },
    methods:{
      submit(ev){
        ev.preventDefault();     
      },
      getUsers(obj=false){
        this.flagDisattivi=0;
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-users', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
              this.users=response.data.users.data        
              this.table.total = response.data.users.total
              this.table.perPage = response.data.users.per_page
          }
        })
      },
      deleteUser(id){
        this.axios.post(this.apiUrl + 'delete-user', {id:id}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.getUsers();             
          }
        });
      },
      removeUser: function(user) {
        this.http.delete('/folder/user', user).then(
          function () { 
            user.list.$remove(user);
          },
          function () {
            alert('Something went wrong.');
          }
        );
      },
      async alertDelete(id) {
        const alert = await alertController
        .create({
          header: 'Attenzione:',
          message: 'Eliminare l\' utenza?',
          buttons: [
            {
              text:'Annulla', 
              role: 'cancel',
            },
            {
            text:'Conferma',
            handler: () => {
              this.deleteUser(id);
            }
            }
          ],
        });
      return alert.present();
    },
    svuotaFiltro(){
      this.search.name='',
      this.search.email='',
      this.search.company='',
      this.search.role=''

      this.getUsers();
    },
    getDisattivi(obj=false){
        this.flagDisattivi=1;
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-users-disattivi', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search }, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.users=response.data.users.data     
            this.table.total = response.data.users.total
            this.table.perPage = response.data.users.per_page         
          }
        })
      },
     attivaUser(id){
        this.axios.post(this.apiUrl + 'attiva-user', {id:id}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.getUsers();              
          }         
        });
      },
  },
  ionViewWillEnter: function(){
    this.getUsers();
  }
}
</script>
<style scoped>
  #container{
    width:90%;
    height:100%;
    margin:5%;
    margin-bottom:5%;
  }
  ion-button{
    width:40%;
  }
  .header{
    background: darkblue;
    font-weight: bold;
    color:white;
    text-align:center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom:5px solid white;
  }
  .headercol{
      border-right:2px inset rgba(33, 209, 200, 0.479);
    }
  .content{
      border-right:2px inset rgba(33, 209, 200, 0.158);
      border-bottom:2px inset rgba(33, 209, 200, 0.158);
    }
  #nuovobtn{
    width:20%;
    margin-top:-5%;
  }

  .ricerca{
    margin:10px;
    border:1px solid grey;
    border-radius:5px;
    margin-bottom:5%;
  }
  #ricdatainizio,#ricdatafine,#ricconsulente,#ricconsulente,#rictipologia,#ricorainizio,#ricorafine,#ricstatus{
    border:1px solid rgb(184, 184, 184);
    border-radius:5px;
  }
  #lstatus,#lconsulente,#ltipologia,#ldatainizio,#ldatafine,#lorainizio,#lorafine{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  .btncerca{
    width:92%;
  }
  .btnfiltro{
    width:5%;
  }
  #buttons{
    display:inline;
  }
  @media only screen and (max-width: 400px) {
    ion-button{
      max-width:90%;
    } 
    #ruolo, #hruolo{
      display:none
    }
  }
  @media only screen and (max-width: 600px) {
    ion-button{
      width:100%;
    }
  }
</style>